import { getCurrentDate } from './apiSport';

const mqtt = require('mqtt');
const pako = require('pako');
// MQTT Configuration
const MQTT_BROKER_URL = `${process.env.REACT_APP_MQTT_PROTOCOL}://${process.env.REACT_APP_MQTT_HOST}${process.env.REACT_APP_MQTT_PATH}`;
// console.log('MQTT_BROKER_URL: ', MQTT_BROKER_URL);
// const MQTT_BROKER_URL = 'wss://mq.api-score.com/mqtt';
const clientId = `mqtt_${Math.random().toString(16).slice(3)}`;
const MQTT_OPTIONS = {
  clientId,
  clean: false,
  connectTimeout: 4000,
  reconnectPeriod: 1000,
};

let client;

const decompressMessage = compressedMessage => {
  try {
    const compressedData = new Uint8Array(compressedMessage);
    const decompressedData = pako.inflate(compressedData, { to: 'string' });
    return JSON.parse(decompressedData);
  } catch (error) {
    console.error(`${getCurrentDate()} Api sport: Error decompressing message:`, error);
    return null;
  }
};

// Connect to MQTT Broker
export const connectMqtt = (onMessageCallback, topic) => {
  client = mqtt.connect(MQTT_BROKER_URL, MQTT_OPTIONS);
  if (!client.connected) {
    client.on('connect', () => {
      console.log(`${getCurrentDate()} Api sport: Connected to MQTT Broker`);
      if (topic) {
        subscribeToTopic(topic);
      }
    });

    client.on('reconnect', () => {
      console.log(`${getCurrentDate()} Api sport: Reconnecting to MQTT Broker...`);
      if (topic) {
        subscribeToTopic(topic);
      } else {
        console.warn(`${getCurrentDate()} Api sport: No topic provided for subscription.`);
      }
    });

    client.on('error', err => {
      console.error('Api sport: MQTT Error:', err);
      client.end();
    });

    client.on('message', (topic, message) => {
      const parsedMessage = decompressMessage(message);
      if (parsedMessage && onMessageCallback) {
        onMessageCallback(topic, parsedMessage);
      }
    });
  }
};

// Subscribe to a Topic
export const subscribeToTopic = topic => {
  if (client && client.connected) {
    client.subscribe(topic, { qos: 0 }, (err, granted) => {
      if (err) {
        console.error(`${getCurrentDate()} Api sport: Subscription error for topic ${topic}:`, err);
      } else {
        console.log(`${getCurrentDate()} Api sport: Subscribed to topic: ${topic}`);
      }
    });
  } else {
    console.warn(
      `${getCurrentDate()} Api sport: Client not connected. Cannot subscribe to topic: ${topic}`,
    );
  }
};

// Publish a Message
export const publishMessage = (topic, message) => {
  if (client) {
    client.publish(topic, message, err => {
      if (err) {
        console.error(`Api sport: Publish error to topic ${topic}:`, err);
      } else {
        console.log(`Api sport: Message published to topic ${topic}: ${message}`);
      }
    });
  }
};

// Disconnect MQTT
export const disconnectMqtt = () => {
  if (client) {
    client.end(() => {
      console.log(`${getCurrentDate()} Api sport: Disconnected from MQTT Broker`);
    });
  }
};
