import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import ItemShowScores from './ItemShowScores';

function ShowScores({ sportType, matchScores }) {
  const [currentMatchId, setCurrentMatchId] = useState('');

  // Swipeable hooks with event handlers
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrevious(),
  });

  useEffect(() => {
    if (matchScores.length > 0) {
      setCurrentMatchId(matchScores[0].matchId);
    }
  }, [matchScores]);

  // Auto-slide
  useEffect(() => {
    if (matchScores.length > 1) {
      const interval = setInterval(() => {
        handleNext();
      }, 10000); // 10 seconds
      return () => clearInterval(interval);
    }
  }, [matchScores, currentMatchId]);

  const handleNext = () => {
    const currentIndex = matchScores.findIndex(match => match.matchId === currentMatchId);
    const nextIndex = (currentIndex + 1) % matchScores.length;
    setCurrentMatchId(matchScores[nextIndex].matchId);
  };

  const handlePrevious = () => {
    const currentIndex = matchScores.findIndex(match => match.matchId === currentMatchId);
    const prevIndex = (currentIndex - 1 + matchScores.length) % matchScores.length;
    setCurrentMatchId(matchScores[prevIndex].matchId);
  };

  return matchScores.length > 0 ? (
    <>
      <div
        {...handlers}
        className="d-lg-flex mt-0 as-scores-items"
        style={{
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {/* Navigation Buttons */}
        {matchScores.length > 1 && (
          <>
            <div className="as-handle-left" onClick={handlePrevious}>
              <img className="as-icon-handle" src="/icon-left.svg" alt="Previous" />
            </div>
            <div className="as-handle-right" onClick={handleNext}>
              <img className="as-icon-handle" src="/icon-right.svg" alt="Next" />
            </div>
          </>
        )}

        {/* Scores Wrapper */}
        <div className="as-scores-bg-wrapper">
          {matchScores.map(match => (
            <ItemShowScores
              key={match.matchId}
              currentMatchId={currentMatchId}
              match={match}
              sportType={sportType}
            />
          ))}
        </div>
      </div>
    </>
  ) : null;
}

export default ShowScores;
