import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { isEmpty, matches } from 'lodash';
import { useDispatch } from 'react-redux';
import Linkify from 'react-linkify';

import { truncateString, pxToRem } from 'utils/common';
import ModalLogin from 'components/storefront/ModalLogin';
import Notification from 'antd-components/notification';
import { MESSAGES_DELETE } from 'components/storefront/Room/ducks';

import MessageInput from './MessageInput';
import MessageList from './MessageList';
import {
  allow_sport_type_show,
  matchDetail,
  matchDetailLive,
  parseMessageMQTT,
  sport_data,
  useUser,
} from 'hooks';
import ModalBan from './ModalBan';
import { getAvatar, senderConvert } from 'utils/common';
import SocialLinks from './SocialLinks';
import ShowScores from './ShowScores';
import { getMatchLive } from 'hooks/RemoteLiveService';
import { connectMqtt, disconnectMqtt } from 'hooks/mqttService';

const OnlineLogin = styled.div`
  text-align: right;
  padding: 5px;
  background: #3a3d44;
  display: flex;
  align-items: center;
  font-size: ${pxToRem(12)};
`;

const Image = styled.img`
  width: 100%;
`;

const RequireLogin = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #3a3d44;
  background: linear-gradient(
    0deg,
    rgba(58, 61, 68, 0.95) 0%,
    rgba(58, 61, 68, 0.5) 65%,
    rgba(58, 61, 68, 0) 100%
  );
  color: #fff;
  text-align: center;
  padding: 30px;
  font-weight: 600;
  font-size: ${pxToRem(20)};
  text-transform: uppercase;
  cursor: pointer;
`;

const RequireLoginDesc = styled.div`
  font-weight: 400;
  font-size: ${pxToRem(16)};
  text-transform: lowercase;
  color: #fff;
`;

const ChatBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

const ChatBoxDesc = styled.div`
  padding: 6px;
  text-align: center;
  background: #faca39;
  color: #333;
  font-weight: bold;
  white-space: pre-line;
  font-size: ${pxToRem(12)};
`;

const ImageCache = styled.img`
  width: 2px;
  height: 2px;
  display: none;
`;

const ChatBox = props => {
  const {
    roomId,
    room,
    messages = [],
    user = {},
    onSend,
    onLoadMore,
    isLoading,
    sportType,
  } = props;
  const { limitMessageLength = 128, limitSendSpeed = 5 } = room.settings || {};

  const [showLogin, setShowLogin] = useState(false);
  const { isManager } = useUser(user);
  const [showBanModal, setShowBanModal] = useState(false);
  const [banUser, setBanUserId] = useState();
  const [isShowScores, setIsShowScores] = useState(false);
  const [isShowInBox, setIsShowInBox] = useState(false);

  const [match, setMatch] = useState({});
  const dispatch = useDispatch();

  const sendCountRef = useRef(0);
  const messageInputRef = useRef();
  const [showChat, setShowChat] = useState(true);

  const [onlineUsers, setOnlineUsers] = useState(0);
  const [colorsConfig, setColorsConfig] = useState({});
  useEffect(() => {
    const fetchOnlineUsers = async () => {
      try {
        const response = await axios.get('https://api.chatboxn.com/api/room/online');
        setOnlineUsers(response.data?.online);
      } catch (error) {
        console.error('Failed to fetch online users:', error);
      }
    };

    const fetchColorsTag = async () => {
      try {
        const colors = await axios.get('https://api.chatboxn.com/api/colors');
        const { data } = colors;
        setColorsConfig(data);
      } catch (error) {
        console.error('Failed to fetch colors:', error);
      }
    };
    // fetchColorsTag();
    fetchOnlineUsers();
    const interval = setInterval(fetchOnlineUsers, 5000); // Fetch every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const handleIsShowScores = isShow => {
    setIsShowScores(isShow);
  };

  const handleSend = message => {
    let mess = message || '';
    if (!isManager) {
      if (sendCountRef.current) {
        Notification.sendMessageError(`Cảnh báo : Bạn sẽ bị ban nếu tiếp tục spam`);
        sendCountRef.current = sendCountRef.current + 1;
        return;
      }

      setTimeout(() => {
        sendCountRef.current = 0;
      }, limitSendSpeed * 1000);

      sendCountRef.current = sendCountRef.current + 1;
    }
    // const { token } = user;

    // if (!isManager) {
    //   mess = removeDomain(mess);
    // }

    onSend &&
      onSend({
        roomId,
        message: truncateString(mess, limitMessageLength),
      });
  };

  const handleLoadmore = () => {
    if (isLoading === true) {
      return;
    }

    onLoadMore && onLoadMore();
  };

  const handleMessageClick = message => {
    try {
      const { value, setValue } = messageInputRef.current || {};
      const { user } = message;
      const name = senderConvert(user.fullname || user.username);
      setValue(`${value} [${name}] `);
    } catch (error) {}
  };

  const handleShowbanModal = banUser => {
    setShowBanModal(true);
    setBanUserId(banUser);
  };

  const handleShowDeleteModal = message => {
    const messageDel = messages.find(item => item.id === message.id);
    dispatch({
      type: MESSAGES_DELETE,
      payload: {
        id: messageDel.id,
        roomId: messageDel.roomId,
        dataRaw: messageDel.dataRaw,
      },
    });
  };

  const closeModalLogin = useCallback(() => setShowLogin(false), []);
  const closeModalBan = useCallback(() => setShowBanModal(false), []);
  const renderImageCache = user => {
    if (!user) return;
    const avatar = getAvatar(user);
    return avatar ? <ImageCache src={avatar} /> : <></>;
  };

  const [matchLive, setMatchLive] = useState([]);
  const [matchScores, setMatchScores] = useState([]);
  const [matchScoreCancel, setMatchScoreCancel] = useState([]);
  const [isShowInBoxCancel, setIsShowInBoxCancel] = useState(false);
  const timeOutRemove = 30000; //30000s
  const timeoutsRef = useRef(new Map()); // To manage setTimeout references
  const timeoutsRefCancel = useRef(new Map()); // To manage setTimeout references
  useEffect(() => {
    const _getMatchLive = async () => {
      try {
        const _live = await getMatchLive('', sportType);
        const _detail_live = await matchDetailLive(sportType);
        const { data } = _live;
        const _matches = [];
        if (data.matches) {
          const teams = data.teams;
          data.matches.map(item => {
            let home_scores = item?.home_scores || [];
            let away_scores = item?.away_scores || [];
            if (_detail_live.data.results) {
              const home = _detail_live.data.results.find(
                detail => detail.id === item.home_team_id,
              );
              const away = _detail_live.data.results.find(
                detail => detail.id === item.away_team_id,
              );
              if (home) {
                home_scores = home?.score[2] || [];
              }
              if (away) {
                away_scores = away?.score[3] || [];
              }
            }
            const home_team = teams.find(team => team.id === item.home_team_id);
            const away_team = teams.find(team => team.id === item.away_team_id);
            const _item = {
              ...item,
              away_team,
              home_team,
              home_scores,
              away_scores,
              matchId: item.id,
              statusId: 4,
              homeTeam: {
                id: home_team.id,
                name: home_team.name,
              },
              awayTeam: {
                id: away_team.id,
                name: away_team.name,
              },
              homeScore: {
                regular: 2,
                ot: 0,
                penalty: 0,
              },
              awayScore: {
                regular: 0,
                ot: 0,
                penalty: 0,
              },
              isScoreCancel: Math.random() < 0.5,
              isHomeGoaled: Math.random() < 0.5,
              timeScored: '82',
            };
            _matches.push(_item);
          });
        }
        if (_matches.length) {
          // const cancel_true = _matches.filter(item => item.isScoreCancel);
          // const cancel_false = _matches.filter(item => !item.isScoreCancel);
          // setTimeout(() => {
          //   pushItemMatchScores([cancel_false[2]]);
          //   pushItemMatchScores([cancel_true[0]], true);
          // }, 2000);
          // setTimeout(() => {
          //   pushItemMatchScores([cancel_false[4]]);
          //   pushItemMatchScores([cancel_true[1]], true);
          // }, 3000);
        }
        setMatchLive(_matches);
      } catch (error) {
        // console.log('Err getMatchDetail: ', error);
        setMatchLive([]);
      }
    };

    if (sportType && allow_sport_type_show.includes(sportType)) {
      // _getMatchLive();
    }
  }, [sportType]);

  const cleanupTimeouts = () => {
    timeoutsRef.current.forEach(timeout => clearTimeout(timeout));
    timeoutsRef.current.clear();

    timeoutsRefCancel.current.forEach(timeout => clearTimeout(timeout));
    timeoutsRefCancel.current.clear();
  };

  const pushItemMatchScores = (newItems, isCancel = false) => {
    const updateScores = setScores => {
      setScores(prev => {
        const updated = [...prev];

        newItems.forEach(newItem => {
          // Kiểm tra xem phần tử đã tồn tại trong mảng hay chưa
          const existingIndex = updated.findIndex(item => item.matchId === newItem.matchId);
          if (existingIndex !== -1) {
            // Nếu phần tử đã tồn tại, reset thời gian tồn tại (xóa và thêm lại)
            clearTimeout(timeoutsRef.current.get(newItem.matchId));
            timeoutsRef.current.delete(newItem.matchId);
            updated.splice(existingIndex, 1);
          }

          updated.unshift(newItem);

          const timeout = setTimeout(() => {
            removeFromMatchScores(newItem.matchId, isCancel);
            if (isCancel) {
              timeoutsRefCancel.current.delete(newItem.matchId);
            } else {
              timeoutsRef.current.delete(newItem.matchId);
            }
          }, timeOutRemove);
          if (isCancel) {
            timeoutsRefCancel.current.set(newItem.matchId, timeout);
          } else {
            timeoutsRef.current.set(newItem.matchId, timeout);
          }
        });

        return updated;
      });
    };

    // Xử lý cho từng trường hợp
    if (isCancel) {
      updateScores(setMatchScoreCancel);
    } else {
      updateScores(setMatchScores);
    }
  };

  const removeFromMatchScores = (id, isCancel = false) => {
    const updateScores = setScores => {
      setScores(prev => prev.filter(item => item.matchId !== id));
    };

    if (isCancel) {
      updateScores(setMatchScoreCancel);
      if (timeoutsRefCancel.current.has(id)) {
        clearTimeout(timeoutsRefCancel.current.get(id));
        timeoutsRefCancel.current.delete(id);
      }
    } else {
      updateScores(setMatchScores);
      if (timeoutsRef.current.has(id)) {
        clearTimeout(timeoutsRef.current.get(id));
        timeoutsRef.current.delete(id);
      }
    }
  };

  useEffect(() => {
    console.log(`--- matchScores ${moment().format('DD/MM/yyyy hh:mm:ss')}: `, matchScores);
    setIsShowInBox(matchScores.length > 0);
  }, [matchScores]);

  useEffect(() => {
    console.log(
      `--- matchScoreCancel ${moment().format('DD/MM/yyyy hh:mm:ss')}: `,
      matchScoreCancel,
    );
    setIsShowInBoxCancel(matchScoreCancel.length > 0);
  }, [matchScoreCancel]);

  useEffect(() => {
    if (sportType && allow_sport_type_show.includes(sportType)) {
      setIsShowScores(true);
    }
  }, [sportType]);

  useEffect(() => {
    if (isShowScores) {
      const isInIframe = window.self !== window.top;
      console.log(`isInIframe ${isInIframe}`);
      const handleMqttConnection = () => {
        const _topic = sport_data[sportType].mqtt_topic || 'fb-live-score';
        // Pass updated matches to parseMessageMQTT
        connectMqtt((topic, message) => {
          if (message && message.length) {
            const cancelTrue = message.filter(item => item.isScoreCancel);
            const cancelFalse = message.filter(item => !item.isScoreCancel);
            if (cancelFalse && cancelFalse.length) {
              pushItemMatchScores(cancelFalse);
            }
            if (cancelTrue && cancelTrue.length) {
              pushItemMatchScores(cancelTrue, true);
            }
          }
        }, _topic);

        // Cleanup on unmount
        return () => {
          disconnectMqtt();
          cleanupTimeouts();
        };
      }
      if (isInIframe) {
        const handleMessage = event => {
          const { type, payload } = event.data;
          console.log(`type ${type}`);
          if (type === 'UPDATE_DATA') {
            // console.log(
            //   `--- ${moment().format('DD/MM/yyyy hh:mm:ss')} Message received in iframe:`,
            //   payload,
            // );
            // Xử lý dữ liệu ở đây
            if (payload && payload.length) {
              const cancelTrue = payload.filter(item => item.isScoreCancel);
              const cancelFalse = payload.filter(item => !item.isScoreCancel);
              if (cancelFalse && cancelFalse.length) {
                pushItemMatchScores(cancelFalse);
              }
              if (cancelTrue && cancelTrue.length) {
                pushItemMatchScores(cancelTrue, true);
              }
            }
          }

          if (type === 'chat-iframe') {
            handleMqttConnection();
          }
        };
        // Gán listener
        window.addEventListener('message', handleMessage);

        return () => {
          // Cleanup listener
          window.removeEventListener('message', handleMessage);
        };
      } else {
        handleMqttConnection();
      }
    }
  }, [isShowScores]);

  return (
    <ChatBoxWrapper>
      <OnlineLogin>
        <SocialLinks showChat={showChat} setShowChat={setShowChat} />
        <div
          className="d-flex"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          {onlineUsers && (
            <>
              <i className="fa-solid fa-user mr-1"></i>
              {onlineUsers}
            </>
          )}
        </div>
      </OnlineLogin>
      {isShowScores && (isShowInBox || isShowInBoxCancel) && (
        <div className="as-scores-main-wrapper">
          {isShowInBox && <ShowScores sportType={sportType} matchScores={matchScores} />}
          {isShowInBoxCancel && <ShowScores sportType={sportType} matchScores={matchScoreCancel} />}
        </div>
      )}
      {showChat && (
        <MessageList
          colorsConfig={colorsConfig}
          user={user}
          messages={messages}
          onYReachStart={handleLoadmore}
          loadingMore={isLoading}
          onClickMessage={handleMessageClick}
          onShowBanModal={handleShowbanModal}
          onShowDeleteModal={handleShowDeleteModal}
          marginTop={isShowScores && (isShowInBox || isShowInBoxCancel) ? '-98px' : '0px'}
        />
      )}
      {renderImageCache(user)}
      {room.isImage && room.image && showChat ? (
        room.link ? (
          <a href={room.link} target="_blank" rel="nofollow">
            <Image src={`${process.env.REACT_APP_IMG_URL}${room.image}`} />
          </a>
        ) : (
          <Image src={`${process.env.REACT_APP_IMG_URL}${room.image}`} />
        )
      ) : (
        <></>
      )}
      {!room.isImage && room.description ? (
        room.link ? (
          <a href={room.link} target="_blank" rel="nofollow">
            <ChatBoxDesc>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    className="message-linkify"
                    target="blank"
                    rel="nofollow"
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {room?.description}
              </Linkify>
            </ChatBoxDesc>
          </a>
        ) : (
          <ChatBoxDesc>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  className="message-linkify"
                  target="blank"
                  rel="nofollow"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {room?.description}
            </Linkify>
          </ChatBoxDesc>
        )
      ) : (
        <></>
      )}
      {showChat &&
        (!isEmpty(user) ? (
          <MessageInput
            attachButton={false}
            onSend={handleSend}
            max={limitMessageLength}
            ref={messageInputRef}
          />
        ) : (
          <RequireLogin
            onClick={() => {
              setShowLogin(true);
              // popitup('https://www.w3schools.com');
            }}
          >
            Đăng Nhập
            <RequireLoginDesc>để tham gia bình luận</RequireLoginDesc>
          </RequireLogin>
        ))}
      <ModalLogin visible={showLogin} onCancel={closeModalLogin} roomId={roomId} />
      {showBanModal && (
        <ModalBan
          user={banUser}
          visible={showBanModal}
          title={'Cấm nhắn tin'}
          onCancel={closeModalBan}
        />
      )}
    </ChatBoxWrapper>
  );
};

export default ChatBox;
