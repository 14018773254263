import React, { useRef, useState, useEffect } from 'react';
import { useEventListener } from 'ahooks';
import styled from 'styled-components';
import { MessageList } from '@chatscope/chat-ui-kit-react';

import MessageItem from './MessageItem';
import { pxToRem } from 'utils/common';

const NewMessageButton = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #faca39;
  z-index: 11;
  bottom: 5px;
  right: 10px;
  cursor: pointer;
`;
const MessageListWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
  z-index: 9;
  margin-top: ${props => props.marginTop || '0px'};
  * {
    color: inherit;
    font-size: ${pxToRem(14)};
  }
  .cs-message-list {
    background: transparent;
  }
  .cs-message__content {
    background: transparent !important;
    color: inherit !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 3px !important;
  }
  .cs-message__content-wrapper {
    flex: 1;
    align-items: flex-start;
  }
  .cs-message__content-sender_message {
    margin-top: 2px;
  }
  .cs-message__sender-name {
    color: rgb(250, 202, 57);
    font-weight: 500;
    display: inline-flex !important;
    align-items: center;
    cursor: pointer;
  }
  .cs-message__sender-name svg {
    margin-left: 5px;
  }
  .cs-message__avatar {
    display: inline-block;
  }
  .cs-message-list__loading-more {
    background: transparent;
  }
  .cs-loader::before {
    border-color: #333333;
  }
  .cs-loader::after {
    border-color: #dedede transparent transparent transparent;
  }
  .ps__thumb-y {
    background-color: #676767 !important;
  }

  .cs-message {
    margin-top: 10px;
    margin-bottom: 0 !important;
  }
  .cs-message__text-content {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
  .cs-message-tmp {
    opacity: 0.3;
  }
  .cs-message-system,
  .cs-message-admin {
    .cs-message__header {
      .cs-message__sender-name,
      .cs-message__sender-sender {
        color: #43d686;
        font-weight: 500;
      }
    }
    .cs-message__content {
      width: 100%;
      /* background: rgba(67, 214, 134, 0.3) !important; */
      background: transparent !important;
    }
    .cs-message__sender-name,
    .cs-message__text-message {
      color: #fabd00;
    }
    .ant-tag {
      background-color: #fabd00 !important;
    }
  }
  .cs-message-separator {
    background: transparent;
    color: #969696;
    &::before,
    &::after {
      background: #969696;
    }
  }
  .metion {
    font-weight: 500;
    color: #009b3a;
    margin-right: 5px;
  }
  .cs-avatar > img {
    object-fit: cover;
  }
  .cs-message-list__scroll-wrapper > .cs-message,
  .cs-message-list__scroll-wrapper > .cs-message-group {
    max-width: 100%;
  }
  .cs-message__avatar {
    width: auto;
  }
  .ant-tag {
    line-height: ${pxToRem(9)};
    font-size: ${pxToRem(9)};
    color: #fff !important;
    padding: 2px 3px 2px 3px;
    min-width: 34px;
    text-align: center;
    border-radius: 3px;
    span {
      font-size: ${pxToRem(11)};
      color: #fff;
      font-style: normal;
      font-weight: 600;
    }
  }
  .cs-avatar.cs-avatar--sm {
    width: 23px;
    height: 23px;
    min-width: 23px;
    min-height: 23px;
    z-index: 9;
  }
`;

const ScrollBottom = ({ target, scrollBottom }) => {
  const [isNewMessage, setIsNewMessage] = useState();

  const isBottom = () => {
    return target?.offsetHeight + target?.scrollTop >= target?.scrollHeight - 5;
  };

  // Thêm message listener khi component được mount
  window.addEventListener('message', event => {
    if (event.data === 'scrollToBottom') {
      scrollBottom();
    }
  });

  useEventListener(
    'scroll',
    () => {
      if (isBottom()) {
        setIsNewMessage(false);
      } else {
        setIsNewMessage(true);
      }
    },
    { target },
  );

  return isNewMessage ? (
    <NewMessageButton onClick={scrollBottom}>
      <i className="fa-solid fa-angles-down"></i>
    </NewMessageButton>
  ) : null;
};

const ChatBox = ({
  messages = [],
  onClickMessage,
  user = {},
  onShowBanModal,
  onShowDeleteModal,
  marginTop,
  colorsConfig = {},
  ...otherProps
}) => {
  const listRef = useRef();
  const scrollDemo = document.querySelector('.scrollbar-container');
  const scrollBottom = () => {
    try {
      const { scrollToBottom } = listRef?.current || {};
      scrollToBottom && scrollToBottom();
    } catch (error) {}
  };
  const getColorTag = data => {
    if (colorsConfig?.data && data?.user) {
      const colors = colorsConfig?.data ?? [];
      const userLevel = data?.user?.level ?? 1;

      if (colors && userLevel) {
        return colors[userLevel - 1];
      }

      return '';
    }

    return '';
  };
  return (
    <MessageListWrapper marginTop={marginTop}>
      <MessageList {...otherProps} ref={listRef}>
        {messages.map(data => (
          <MessageItem
            onShowBanModal={onShowBanModal}
            onShowDeleteModal={onShowDeleteModal}
            data={data}
            user={user}
            onClickMessage={onClickMessage}
            key={data.id || data.idTmp}
            colorTag={getColorTag(data)}
          />
        ))}
      </MessageList>
      <ScrollBottom target={scrollDemo} scrollBottom={scrollBottom} />
    </MessageListWrapper>
  );
};

export default ChatBox;
