import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { Tag, Slider } from 'antd';

import Button from 'antd-components/button';
import Modal from 'antd-components/modal';
import ImageUploading from 'react-images-uploading';
import { UPDATE_AVATAR_REQUEST, UPDATE_USER } from 'components/dashboard/Users/ducks';
import { useDispatch, connect } from 'react-redux';
import ModalUpdateInfo from './ModalUpdateInfo';
import ModalUpdatePassword from './ModalUpdatePassword';
import Notification from 'antd-components/notification';
import { pxToRem, getAvatar } from 'utils/common';
import { useUser } from 'hooks';
import { handleImageError, getBaseName } from 'utils/common';

const StyledForm = styled.div`
  .action-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .field-group > div {
    // margin-bottom: 15px;
  }

  .avatar-box {
    margin-bottom: 15px;

    .label {
      margin-bottom: 12px;
    }
    img {
      margin-bottom: 10px;
    }

    button {
      margin-right: 10px;
    }

    .image-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .username {
        margin-top: 10px;
        font-size: 16px;
      }

      .avatar-rounded {
        position: relative;
        width: ${pxToRem(90)};
        height: ${pxToRem(90)};
        border-radius: 50%;
        cursor: pointer;

        :hover {
          .cover-box {
            display: flex;
          }
        }

        img {
          border-radius: 50%;
          cursor: pointer;
          max-width: ${pxToRem(90)};
          max-height: ${pxToRem(90)};
          width: ${pxToRem(90)};
          height: ${pxToRem(90)};
          object-fit: cover;
        }

        .cover-box {
          position: absolute;
          width: ${pxToRem(90)};
          height: ${pxToRem(90)};
          border-radius: 50%;
          top: 0;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          display: none;
          flex-direction: column;
          .camera-icon {
            font-size: ${pxToRem(28)};
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .title {
    font-size: ${pxToRem(20)};
  }
  .cards {
    margin-top: 10px;
    display: flex;
    /* justify-content: space-between; */
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;

    .update-card {
      border-radius: 4px;
      border: 1px solid #ddd;
      padding: 5px;
      min-width: 200px;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex: 1;
      margin: ${pxToRem(7)} ${pxToRem(10)};
      .icon-box {
        width: ${pxToRem(28)};

        i {
          font-size: ${pxToRem(18)};
          margin-right: ${pxToRem(7)};
          color: gray;
        }
      }
    }
  }

  .logout-box {
    text-align: right;
    margin-top: 30px;
    i {
      margin-left: 10px;
    }
  }
`;

const UserLevel = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: auto;
  max-width: 100%;
  .ant-slider {
    flex: 1;
    margin: 0;
  }
`;

const ModalUpdateProfile = props => {
  const { visible, title, onCancel, userStore } = props;
  const { user } = userStore;
  const { username, fullname } = user;
  const { 
    level,
    messageCount,
    // messageCountLevel,
    color 
  } = useUser(user);

  const defaultFullname = fullname || username;

  const initialValues = {
    id: user.id,
    fullname: defaultFullname,
  };
  
  function messagesToNextLevel(totalMessages, limitCountLevel = 500, initialLevelThreshold = 20) {
    let messagesNeededForThisLevel;
    let messagesInCurrentLevel;
  
    if (level < initialLevelThreshold) {
      // Level từ 1 đến 20
      messagesNeededForThisLevel = limitCountLevel;
      messagesInCurrentLevel = totalMessages % messagesNeededForThisLevel;
    } else if (level < 100) {
      // Level từ 21 đến 99
      messagesNeededForThisLevel = limitCountLevel * 4;
      messagesInCurrentLevel = (totalMessages - (limitCountLevel * initialLevelThreshold)) % messagesNeededForThisLevel;
    } else {
      // Level từ 100 trở đi
      messagesNeededForThisLevel = limitCountLevel * 10;
      const messagesBeyondLevel99 = totalMessages - ((limitCountLevel * initialLevelThreshold) +  ((99 - initialLevelThreshold) * (limitCountLevel * 4)));
      messagesInCurrentLevel = messagesBeyondLevel99 % messagesNeededForThisLevel;
    }
  
    const remainingMessagesForNextLevel = messagesNeededForThisLevel - messagesInCurrentLevel;
    
    return {
      currentLevel: level,
      messagesNeededForThisLevel,
      remainingMessagesForNextLevel,
    };
  }  

  const { 
    // currentLevel,
    messagesNeededForThisLevel,
    remainingMessagesForNextLevel 
  } = messagesToNextLevel(messageCount);
  // const messagesSentThisLevel = messageCountLevel - ((currentLevel - 1) * (currentLevel <= 20 ? 500 : 2000));

  const dispatch = useDispatch();
  const [images, setImages] = useState([
    {
      dataUrl: getAvatar(user),
    },
  ]);
  const [showUpdateInfoModal, setShowUpdateInfoModal] = useState(false);
  const [showUpdatePassModal, setShowUpdatePassModal] = useState(false);

  const onChange = imageList => {
    dispatch({
      type: UPDATE_AVATAR_REQUEST,
      payload: {
        avatar: imageList[0].file,
        callBack: success => {
          if (success) {
            Notification.success('Thay đổi ảnh thành công');
            if (success.avatar) {
              setImages([{ dataUrl: `${process.env.REACT_APP_API_URL}/uploads/thumbs/${getBaseName(success.avatar)}` }]);
            }
          }
        },
      },
    });
  };

  const handleLogout = () => {
    dispatch({ type: UPDATE_USER, payload: null });
    onCancel();
  };

  const renderForm = ({ handleSubmit, ...form }) => (
    <Form className="form">
      <div className="field-group">
        <div className="avatar-box">
          <div>
            <ImageUploading value={images} onChange={onChange} dataURLKey="dataUrl">
              {({ imageList, onImageUpdate }) => (
                <div className="upload__image-wrapper">
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <div className="avatar-rounded" onClick={() => onImageUpdate(index)} >
                        <img src={image.dataUrl} width="150" onError={(e) => handleImageError(e, `${process.env.REACT_APP_API_URL}/uploads/thumbs/${getBaseName(user?.avatar)}`, defaultFullname, 'info')}/>

                        <div className="cover-box">
                          <i className="fa-solid fa-camera camera-icon"></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
        <div className="text-center">
          <div className="username">{defaultFullname}</div>
          <UserLevel>
            <Tag color={color}>{`lv${level || 1}`}</Tag>
            <Slider
              defaultValue={messagesNeededForThisLevel - remainingMessagesForNextLevel}
              min={0}
              max={messagesNeededForThisLevel}
              handleStyle={{
                display: 'none',
              }}
              trackStyle={{
                backgroundColor: '#faca39',
              }}
            />
            <span className="ml-2">
            {
            `${messagesNeededForThisLevel - remainingMessagesForNextLevel < 0 ? 0 : (messagesNeededForThisLevel - remainingMessagesForNextLevel)}
            /${messagesNeededForThisLevel}`
            }</span>
          </UserLevel>
        </div>
        <div className="cards">
          <div className="update-card" onClick={() => setShowUpdateInfoModal(true)}>
            <div className="icon-box">
              <i className="fa-regular fa-address-card"></i>
            </div>
            <div>Thay đổi thông tin</div>
          </div>
          <div className="update-card" onClick={() => setShowUpdatePassModal(true)}>
            <div className="icon-box">
              <i className="fa-solid fa-lock"></i>
            </div>
            <div>Đổi mật khẩu</div>
          </div>
          <div className="update-card" onClick={handleLogout}>
            <div className="icon-box">
              <i className="fa-solid fa-arrow-right-from-bracket"></i>
            </div>
            Đăng xuất
          </div>
        </div>
      </div>
    </Form>
  );

  return (
    <Modal title={title} visible={visible} onCancel={onCancel}>
      <StyledForm>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          component={renderForm}
        />
      </StyledForm>
      {showUpdateInfoModal && (
        <ModalUpdateInfo
          visible={showUpdateInfoModal}
          title={'Cập nhật thông tin'}
          onCancel={() => setShowUpdateInfoModal(false)}
        />
      )}
      {showUpdatePassModal && (
        <ModalUpdatePassword
          visible={showUpdatePassModal}
          title={'Cập nhật mật khẩu'}
          onCancel={() => setShowUpdatePassModal(false)}
        />
      )}
    </Modal>
  );
};

export default connect(({ user }) => ({
  userStore: user,
}))(ModalUpdateProfile);
